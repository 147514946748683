import { Heading, Icon, Pane, Text } from 'evergreen-ui';

const UserCard = ({ icon, name, username }) => {
    return (
        <Pane display="flex" flexDirection="row" marginTop={12}>
            <Icon icon={icon} size={24} marginTop={4} />
            <Pane display="flex" flexDirection="column" marginLeft={8}>
                <Heading marginTop={0}>{name}</Heading>
                <Text>{username}</Text>
            </Pane>
        </Pane>
    );
};

export default UserCard;
