import { ArrowLeftIcon, Card, Heading, IconButton, Pane, Spinner, Text, toaster } from 'evergreen-ui';
import TopHeader from '../components/TopHeader';

import TEST_DATA from '../contexts/TEST_USER.json';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from '../helpers/formatter';
import { apiGetProducts } from '../helpers/axios';
import { useUserContext } from '../contexts/UserContext';
import { calculateCommission } from '../helpers/commission';

const CatalogCompany = ({ match }) => {
    const [company, setCompany] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const nav = useNavigate();
    const params = useParams();
    const companyId = params.company_id;
    const { currentUser } = useUserContext();

    const fetchData = async () => {
        try {
            const res = await apiGetProducts(companyId);
            setCompany(res);
        } catch (e) {
            console.error(e);
            toaster.danger("Something went wrong, we're investigating right now", { id: 'ERROR_500' });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) return <Spinner />;

    return (
        <Pane padding={16}>
            <TopHeader />
            <Pane padding={8} marginTop={16}>
                <Pane display="flex" flexDirection="row" alignItems="center">
                    <IconButton icon={ArrowLeftIcon} marginRight={8} onClick={() => nav(`/catalog`)} />
                    <Heading size={700}>{company.name}</Heading>
                </Pane>
            </Pane>
            <Pane display="flex" width="100%" flexDirection="column">
                {company.products.map((item) => (
                    <Card
                        elevation={2}
                        marginBottom={8}
                        paddingRight={16}
                        paddingLeft={16}
                        paddingTop={24}
                        paddingBottom={24}
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        onClick={() => nav(`/catalog/${companyId}/${item.id}`)}
                    >
                        <Pane
                            width="100%"
                            style={{ aspectRatio: '3 / 2' }}
                            backgroundColor="#edeff5"
                            backgroundImage={`url(${item.image_url})`}
                            backgroundSize="cover"
                            backgroundPosition="center"
                        />
                        <Pane marginTop={16}>
                            <Heading size={600} marginTop={8}>
                                {item.name}
                            </Heading>
                            <Heading size={100} marginTop={16}>
                                RECOMMENDED SELL PRICE
                            </Heading>
                            <Text>{formatCurrency(item.recommended_sell_price)}</Text>
                            <Heading size={100} marginTop={16}>
                                YOUR COMMISSION
                            </Heading>
                            <Text>{formatCurrency(item.recommended_sell_price - item.topline_agent_price)}</Text>
                        </Pane>
                    </Card>
                ))}
            </Pane>
        </Pane>
    );
};

export default CatalogCompany;
