import {
    Button,
    EyeOffIcon,
    EyeOnIcon,
    Heading,
    IconButton,
    Link,
    Pane,
    ResetIcon,
    Spinner,
    TextInput,
    toaster
} from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { apiCheckSession, apiReset } from '../helpers/axios';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const Reset = () => {
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [badCheck, setBadCheck] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
    const [initLoading, setInitLoading] = useState(true);
    const [isProcessCompleted, setIsProcessCompleted] = useState(false);
    const { currentUser } = useUserContext();
    const nav = useNavigate();
    const [search, _] = useSearchParams();

    const handleSubmit = async () => {
        if (!password || !cpassword) {
            toaster.danger('Please enter all required fields', { id: 'REQUIRED' });
            return;
        }
        if (password !== cpassword) {
            toaster.danger('The password fields are not the same, please re enter', { id: 'PASSWORD_MISMATCH' });
            return;
        }

        try {
            setIsLoading(true);
            await apiReset({
                id: search.get('id'),
                new_password: password,
                session_id: search.get('session')
            });
            setIsLoading(false);
            setIsProcessCompleted(true);
        } catch (err) {
            toaster.danger('Something went wrong, we are investigating right now', { id: 'ERROR_500' });
        }
    };

    const checkSession = async () => {
        try {
            if (!search.get('id') || !search.get('session')) {
                throw new Error('invalid url');
            }
            await apiCheckSession(search.get('session'));
        } catch (err) {
            setBadCheck(true);
        } finally {
            setInitLoading(false);
        }
    };

    const handleToLogin = () => {
        nav('/login');
    };

    const handleHidden = () => {
        setIsHidden(!isHidden);
    };

    useEffect(() => {
        if (currentUser) nav('/profile');
        checkSession();
    }, []);

    if (initLoading) return <Spinner />;

    if (badCheck) {
        return (
            <Pane display="flex" flexDirection="column" alignItems="center" gap={8} paddingTop={32}>
                <Heading size={500}>The page you're looking for is not here or your link has expired</Heading>
            </Pane>
        );
    }

    if (isProcessCompleted) {
        return (
            <Pane display="flex" flexDirection="column" alignItems="center" gap={8} paddingTop={32}>
                <Heading size={500}>
                    You have reset your password, please login{' '}
                    <Link cursor="pointer" onClick={handleToLogin}>
                        here
                    </Link>
                </Heading>
            </Pane>
        );
    }

    return (
        <Pane display="flex" flexDirection="column" alignItems="center" gap={8} padding={32} height="80vh">
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Heading size={900}>Reset Password</Heading>
            <Pane>
                <Pane display="flex" flexDirection="column" alignItems="flex-start">
                    <Heading size={100} marginBottom={4}>
                        New Password
                    </Heading>
                    <Pane>
                        <TextInput
                            placeholder="******"
                            type={isHidden ? 'password' : 'text'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <IconButton marginLeft={8} icon={isHidden ? EyeOnIcon : EyeOffIcon} onClick={handleHidden} />
                    </Pane>

                    <Heading size={100} marginBottom={4} marginTop={8}>
                        Confirm New Password
                    </Heading>
                    <TextInput
                        placeholder="******"
                        type="password"
                        value={cpassword}
                        onChange={(e) => setCpassword(e.target.value)}
                    />
                </Pane>

                <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={280}
                    marginTop={16}
                >
                    <Button appearance="primary" onClick={handleSubmit} iconBefore={ResetIcon} isLoading={isLoading}>
                        Reset Password
                    </Button>
                </Pane>
            </Pane>
        </Pane>
    );
};

export default Reset;
