import {
    ArrowLeftIcon,
    Button,
    Card,
    Heading,
    IconButton,
    LogInIcon,
    NewPersonIcon,
    Pane,
    Paragraph,
    Strong,
    Text
} from 'evergreen-ui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import banner from '../assets/banner-1.png';
import { useEffect, useState } from 'react';
import { apiGetCompanies, apiGetProducts } from '../helpers/axios';
import Footer from '../components/Footer';

const Home = () => {
    const nav = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const [search, setSearch] = useSearchParams();

    const handleNavigate = (id) => {
        setSearch({ company: id });
        fetchProducts(id);
    };

    const fetchData = async () => {
        try {
            const res = await apiGetCompanies(null);
            setCompanies(res);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const fetchProducts = async (id) => {
        try {
            const res = await apiGetProducts(id);
            setCompany(res);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const handleBack = () => {
        setSearch({});
        setCompany(null);
    };

    useEffect(() => {
        const id = search.get('company');
        fetchData();
        if (id) {
            fetchProducts(id);
        } else {
            setCompany(null);
        }
    }, []);

    useEffect(() => {
        const id = search.get('company');
        fetchData();
        if (id) {
            fetchProducts(id);
        } else {
            setCompany(null);
        }
    }, [search]);

    return (
        <Pane display="flex" flexDirection="column" alignItems="flex-start" gap={8} paddingTop={32} padding={16}>
            <Pane
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom={16}
                borderBottom="1px solid #ebebeb"
            >
                <Heading size={700}>VinT Agency</Heading>
                <Pane display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" gap={16}>
                    <Button appearance="primary" onClick={() => nav('/login')} iconBefore={LogInIcon}>
                        Login
                    </Button>
                    <Button appearance="default" onClick={() => nav('/signup')} iconBefore={NewPersonIcon}>
                        Register
                    </Button>
                </Pane>
            </Pane>
            <img
                src={banner}
                alt="banner"
                style={{
                    width: '100%',
                    aspectRatio: '2/1',
                    borderRadius: '8px'
                }}
            />
            <Pane marginTop={8} padding={16} border="1px solid #DEDEDE" borderRadius={8}>
                <Paragraph>
                    <Strong>Welcome to VinT Agency - Agency Sales di Indonesia.</Strong> Sebuah platform untuk kamu yang
                    ingin memulai usaha dengan modal Rp 100.000 dengan berbagai manfaat dan keunggulan! Bagi kalian yang
                    memiliki usaha butuh bantuan tim sales untuk meningkatkan penjualan kalian silahkan coba untuk
                    mengajukan kerjasama dengan kami!
                </Paragraph>
            </Pane>
            <Pane display="flex" flexDirection="row" alignItems="center" marginTop={16}>
                {company != null && <IconButton icon={ArrowLeftIcon} onClick={handleBack} marginRight={8} />}
                <Heading size={700}>{company?.name ? company.name : 'Company Partners'}</Heading>
            </Pane>

            <Pane display="flex" flexWrap="wrap" width="100%" justifyContent="space-between">
                {company === null &&
                    companies.map((item) => (
                        <Card
                            elevation={2}
                            margin={8}
                            paddingRight={16}
                            paddingLeft={16}
                            paddingTop={24}
                            paddingBottom={24}
                            onClick={() => handleNavigate(item.id)}
                            flexGrow={1}
                            flexBasis={150}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <img src={item.image_url} width={120} height={120}></img>
                            <Pane width={120}>
                                <Heading marginTop={8} wordWrap="break-word">
                                    {item.name}
                                </Heading>
                            </Pane>
                        </Card>
                    ))}

                {company != null &&
                    company.products.map((item) => (
                        <Card
                            elevation={2}
                            marginBottom={8}
                            paddingRight={16}
                            paddingLeft={16}
                            paddingTop={24}
                            paddingBottom={24}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Pane
                                width="100%"
                                style={{ aspectRatio: '3 / 2' }}
                                backgroundColor="#edeff5"
                                backgroundImage={`url(${item.image_url})`}
                                backgroundSize="cover"
                                backgroundPosition="center"
                            />
                            <Pane marginTop={16}>
                                <Heading size={600} marginTop={8}>
                                    {item.name}
                                </Heading>
                                <Text marginTop={16}>{item.description}</Text>
                            </Pane>
                        </Card>
                    ))}
            </Pane>
        </Pane>
    );
};

export default Home;
