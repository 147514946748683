import {
    Button,
    Heading,
    ListItem,
    LogInIcon,
    LogOutIcon,
    NewPersonIcon,
    Pane,
    Paragraph,
    Strong,
    Text,
    UnorderedList
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const Contact = () => {
    const nav = useNavigate();

    return (
        <Pane padding={32} height="80vh">
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Pane width="100%" marginTop={16} display="flex" flexDirection="column" marginBottom={128}>
                <Heading size={700} marginBottom={16}>
                    Contact Us
                </Heading>
                <Pane marginBottom={8}>
                    <Strong>Email</Strong>
                    <Text marginLeft={8}>work.kevintan@gmail.com</Text>
                </Pane>
                <Pane marginBottom={8}>
                    <Strong>WA (Chat Only)</Strong>
                    <Text marginLeft={8}>+628111727995</Text>
                </Pane>
                <Pane marginBottom={8}>
                    <Strong>Instagram</Strong>
                    <Text marginLeft={8}>@vintagency.id</Text>
                </Pane>
                <Pane marginBottom={8}>
                    <Strong>Youtube</Strong>
                    <Text marginLeft={8}>VinTAgency</Text>
                </Pane>
            </Pane>
        </Pane>
    );
};

export default Contact;
