import {
    Button,
    Heading,
    ListItem,
    LogInIcon,
    LogOutIcon,
    NewPersonIcon,
    Pane,
    Paragraph,
    UnorderedList
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const Tnc = () => {
    const nav = useNavigate();

    return (
        <Pane padding={32}>
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Pane width="100%" marginTop={16} display="flex" flexDirection="column" marginBottom={128}>
                <Heading size={700} marginBottom={16}>
                    Terms and Conditions
                </Heading>
                <Paragraph textAlign="left">
                    <UnorderedList marginTop={8} marginLeft={32}>
                        <ListItem>
                            TIDAK BOLEH ADA YANG MELAKUKAN RECRUITMENT UNTUK AGENCY KESEMPATAN KERJA ATAU APAPUN DI
                            DALAM AGENCY
                        </ListItem>
                        <ListItem>
                            ***TIDAK BOLEH ASAL MENGHUBUNGI AGENT/REKAN DI AGENCY UNTUK KEPENTINGAN PRIBADI DAN TANPA
                            SEIJIN ORANGNYA LANGSUNG (JIKA ADA ORANG TIDAK KENAL MENGHUBUNGI KAMU DAN DIA PART OF VINT
                            AGENCY MOHON SEGERA DI LAPORKAN)
                        </ListItem>
                        <ListItem>TIDAK BOLEH MELAKUKAN HAL-HAL YANG BISA MERUGIKAN ORANG LAIN</ListItem>
                        <ListItem>
                            Dilarang untuk menyampaikan pernyataan ofensif secara lisan maupun tulisan yang mengandung
                            unsur ras, warna kulit, gender, disabilitas, kewarganegaraan, agama, dan segala bentuk
                            provokasi kepada setiap subyek yang berhubungan dengan agency. Pihak yang merasa dirugikan
                            dapat memberikan laporan kepada manajemen agency.
                        </ListItem>
                        <ListItem>
                            TIDAK BOLEH BERBICARA ASAL ATAU MEMBUAT ONAR DI DALAM GRUP ATAUPUN HASUTAN KEPADA SIAPAPUN
                            YANG BERHUBUNGAN DENGAN VINT AGENCY ***TIDAK BOLEH KURANG AJAR ATAU MENGANGGU REKAN YANG
                            LAIN (JIKA ADA YANG TERGANGGU SILAHKAN SEGERA DI LAPORKAN)
                        </ListItem>
                        <ListItem>
                            DILARANG MEMBAHAS HAL-HAL YANG MEMICU KERESAHAN DAN MEMECAHBELAH (ADMIN BERHAK MENGHAPUS
                            TULISAN-TULISAN YANG TIDAK SESUAI UNTUK KOMUNITAS KITA)
                        </ListItem>
                        <ListItem>
                            Dilarang keras untuk menggunakan, bertindak, dan membuat keputusan atas nama agency yang
                            bersifat menipu, tidak etis, dan melanggar hukum.
                        </ListItem>
                    </UnorderedList>
                    Dalam hal menilai pelanggaran kode etik ini, agency akan mempertimbangkan seluruh fakta dan
                    informasi yang relevan, termasuk namun tidak terbatas pada faktor-faktor yang dijabarkan dalam
                    aturan diatas. Pelanggaran dapat mengakibatkan tindakan disipliner, termasuk pemutusan hubungan
                    kerja sama yang bersifat mutlak dam sepihak.
                </Paragraph>
            </Pane>
        </Pane>
    );
};

export default Tnc;
