import './App.css';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import { Pane } from 'evergreen-ui';
import Footer from './components/Footer';
import { COLORS } from './components/atoms/colors';

function App() {
    return (
        <Pane height="auto" minHeight="100vh">
            <BrowserRouter>
                <Pane width="100%" maxWidth={600} margin="auto">
                    <MainRoutes />
                </Pane>
                <Pane width="100%" maxWidth={600} margin="auto">
                    <Footer />
                </Pane>
            </BrowserRouter>
        </Pane>
    );
}

export default App;
