import { Heading, ListItem, Pane, Paragraph, UnorderedList } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const PrivacyPolicy = () => {
    const nav = useNavigate();

    return (
        <Pane padding={32}>
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Pane width="100%" marginTop={16} display="flex" flexDirection="column" marginBottom={128}>
                <Heading size={700} marginBottom={16}>
                    Privacy Policy
                </Heading>
                <Paragraph>Berlaku sejak November 2023</Paragraph>
                <Paragraph marginTop={8} textAlign="justify">
                    VinT Agency menghormati hal-hal yang berhubungan dengan privasi Anda. Pemberitahuan privasi ini
                    menjelasikan jenis informasi pribadi yang kami kumpulkan, bagaimana kami menggunakan informasi
                    tersebut, dengan siapa kami membagi informasi tersebut dan pilihan yang dapat anda buat mengenai
                    pengumpulan, penggunaan dan pilihan yang dapat anda buat mengenai pengumpulan, penggunaan dan
                    pengungkapan informasi tersebut oleh kami. Kami juga menjelaskan langkah-langkah yang kami ambil
                    guna melindungi keamanan informasi dan bagaimana anda dapat menghubungi kami tentang praktik privasi
                    kami. Kebijakan Privasi ini tunduk pada hukum Negara/Wilayah Indonesia. Informasi pribadi anda yang
                    kami kumpulkan dapat diperoleh dari berbagai sumber, termasuk dari website vint.my.id, atau melalui
                    Agent yang bekerjasama dengan kami. Saat anda membuat akun/mendaftar member, kami akan mengumpulkan
                    informasi pribadi, termasuk namun tidak terbatas pada ; nama lengkap, no hp, nomer rekening, dan
                    kota domisili. Informasi lainnya dapat di lengkapi jika anda berkenan untuk mendaftarkan. Penggunaan
                    Informasi yang di kumpulkan kami dapat menggunakan informasi tentang anda untuk:
                    <UnorderedList marginTop={8} marginLeft={32}>
                        <ListItem>
                            Mendaftarkan keanggotaan anda di situs kami, serta mengelola dan memelihara akun anda di
                            situs kami
                        </ListItem>
                        <ListItem>Menyediakan produk atau layanan yang anda minta</ListItem>
                        <ListItem>
                            Memproses, memvalidasi, mengkonfirmasi, memverifikasi, mengirim dan melacak transaksi anda
                            (termasuk proses penjualan, pembelian, pembagian hasil, dan menghubungi anda terkait hal
                            yang berhubungan dengan transaksi)
                        </ListItem>
                        <ListItem>Merekam catatan Transaksi dan komisi anda di situs kami</ListItem>
                        <ListItem>
                            Memberikan tanggapan atas pertanyaan dan komentar anda, juga menyediakan bantuan pelanggan
                        </ListItem>
                        <ListItem>
                            Memberikan informasi produk, layanan, penawaran, acara dan bahan promosi kami, serta
                            penawaran menarik.
                        </ListItem>
                        <ListItem>
                            Mempublikasikan hasil transaksi anda, termasuk di situs dan jejaring sosial media Agency
                            untuk keperluan dalam tim
                        </ListItem>
                        <ListItem>
                            Menyesuaikan produk dan layanan yang sesuai dengan minat pribadi anda ketika menggunakan
                            situs atau aset media sosial Agency
                        </ListItem>
                        <ListItem>
                            Memberikan perlindungan terhadap dan mencegah penipuan, transaksi tidak sah, klaim dan
                            kewajiban lainnya, serta mengelola munculnya risiko, termasuk dengan mengidentifikasi adanya
                            potensi peretas dan pengguna lainnya yang tidak sah
                        </ListItem>
                        <ListItem>Menegakan ketentuan penggunaan serta syarat dan ketentuan situs Agency</ListItem>
                        <ListItem>
                            Mematuhi persyaratan hukum, standar industri dan kebijakan Agency yang berlaku
                        </ListItem>
                    </UnorderedList>
                </Paragraph>
            </Pane>
        </Pane>
    );
};

export default PrivacyPolicy;
