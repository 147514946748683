import {
    Button,
    Checkbox,
    Combobox,
    Heading,
    Link,
    LogInIcon,
    Pane,
    Paragraph,
    Text,
    TextInput,
    toaster
} from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { CITIES } from '../helpers/kota';
import ErrorBox from '../components/ErrorBox';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [domicile, setDomicile] = useState(CITIES[0]);
    const [phone, setPhone] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [bankName, setBankName] = useState('');
    const [uplineUsername, setUplineUsername] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState({});
    const { signup, currentUser } = useUserContext();
    const [isTNCChecked, setIsTNCChecked] = useState(false);
    const nav = useNavigate();

    const handleSubmit = async () => {
        console.log(formError);
        const payload = {
            username,
            password,
            fullName,
            email,
            domicile,
            phone,
            bank_account: bankAccount,
            bank_name: bankName,
            upline_username: uplineUsername !== '' ? uplineUsername : null
        };

        const errorCodes = [];

        if (username === '') {
            errorCodes.push('USERNAME_REQUIRED');
        }
        if (password === '') {
            errorCodes.push('PASSWORD_REQUIRED');
        }
        if (password.length < 8) {
            errorCodes.push('PASSWORD_LENGTH');
        }
        if (fullName === '') {
            errorCodes.push('FULLNAME_REQUIRED');
        }
        if (email === '') {
            errorCodes.push('EMAIL_REQUIRED');
        }
        if (domicile === '') {
            errorCodes.push('DOMICILE_REQUIRED');
        }
        if (phone === '') {
            errorCodes.push('PHONE_REQUIRED');
        }
        if (bankAccount === '') {
            errorCodes.push('BANKACCOUNT_REQUIRED');
        }
        if (bankName === '') {
            errorCodes.push('BANKNAME_REQUIRED');
        }
        if (!isTNCChecked) {
            errorCodes.push('TNC_REQUIRED');
        }

        if (errorCodes.length > 0) {
            console.log(errorCodes);
            addErrorCodeMany(errorCodes);
            toaster.danger('All fields are required! Please review the form.', { id: 'FORM_REQUIRED' });
            return;
        }

        setFormLoading(true);
        try {
            await signup(payload);
            toaster.success('Signup Successful!', { id: 'SIGNUP_SUCCESS' });
            nav('/profile');
        } catch (err) {
            const errorCode = err?.response?.data?.error_code;
            if (errorCode) addErrorCode(errorCode);

            if (errorCode === 'EMAIL_EXISTS_ERROR') {
                toaster.danger('The provided email is already registered. Please use a different email', {
                    id: 'SIGNUP_ERROR'
                });
            }
            if (errorCode === 'PHONE_EXISTS_ERROR') {
                toaster.danger('The provided phone number is already registered. Please use a different phone number', {
                    id: 'SIGNUP_ERROR'
                });
            }
            if (errorCode === 'USERNAME_EXISTS_ERROR') {
                toaster.danger('The provided username is already registered. Please use a different username', {
                    id: 'SIGNUP_ERROR'
                });
            }
            if (errorCode === 'UPLINE_NOT_FOUND') {
                toaster.danger('We cannot find the Referer username you provided. Please double check', {
                    id: 'SIGNUP_ERROR'
                });
            }
        }
        setFormLoading(false);
    };

    const addErrorCode = (errorCode) => {
        const temp = {};
        temp[errorCode] = true;
        setFormError(temp);
    };

    const addErrorCodeMany = (errorCodes) => {
        const temp = {};
        for (let i = 0; i < errorCodes.length; i++) {
            temp[errorCodes[i]] = true;
        }
        setFormError(temp);
    };

    const handleToLogin = () => {
        nav('/login');
    };

    useEffect(() => {
        if (currentUser) nav('/profile');
    }, []);

    return (
        <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={8}
            paddingTop={32}
            paddingBottom={64}
            paddingX={16}
        >
            <HeaderV2 nav={nav} />
            <Spacer size={64} />

            <Heading size={900} marginBottom={0}>
                Sign Up
            </Heading>
            <Pane paddingX={32}>
                <Paragraph textAlign="center">
                    Halo! Tinggal satu langkah saja kamu bisa jadi bagian di VinT Agency! Platform yang tepat untuk
                    dapat income tambahan, komisi, dan juga cashback
                </Paragraph>
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Username
                </Heading>
                <TextInput placeholder="johndoe" value={username} onChange={(e) => setUsername(e.target.value)} />
                <ErrorBox text="Username is Required" isShown={formError['USERNAME_REQUIRED']} />
                <ErrorBox text="This username is taken" isShown={formError['USERNAME_EXISTS_ERROR']} />
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Email
                </Heading>
                <TextInput placeholder="johndoe@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                <ErrorBox text="Email is Required" isShown={formError['EMAIL_REQUIRED']} />
                <ErrorBox text="This email is taken" isShown={formError['EMAIL_EXISTS_ERROR']} />
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Full Name
                </Heading>
                <TextInput placeholder="John Doe" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                <ErrorBox text="Full Name is Required" isShown={formError['FULLNAME_REQUIRED']} />
            </Pane>

            <Pane width="100%" maxWidth={280}>
                <Heading size={100} marginBottom={4}>
                    Domicile
                </Heading>
                <Combobox
                    items={CITIES}
                    selectedItem={domicile}
                    onChange={(selected) => setDomicile(selected)}
                    placeholder="Domicile"
                    autocompleteProps={{
                        title: 'Cities in Indonesia'
                    }}
                    width="100%"
                />
                <ErrorBox text="Domicile is Required" isShown={formError['DOMICILE_REQUIRED']} />
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Phone Number
                </Heading>
                <TextInput placeholder="081234567890" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <ErrorBox text="Phone Number is Required" isShown={formError['PHONE_REQUIRED']} />
                <ErrorBox text="This phone number is taken" isShown={formError['PHONE_EXISTS_ERROR']} />
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Bank Account Number
                </Heading>
                <TextInput
                    placeholder="1234567890"
                    value={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                />
                <ErrorBox text="Bank Account is Required" isShown={formError['BANKACCOUNT_REQUIRED']} />
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Bank Name
                </Heading>
                <TextInput
                    placeholder="BCA/Mandiri/BNI/BRI"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                />
                <ErrorBox text="Bank Name is Required" isShown={formError['BANKNAME_REQUIRED']} />
            </Pane>

            <Pane display="flex" flexDirection="column" alignItems="flex-start">
                <Heading size={100} marginBottom={4}>
                    Password
                </Heading>
                <TextInput
                    placeholder="****"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <ErrorBox text="Password is Required" isShown={formError['PASSWORD_REQUIRED']} />
                <ErrorBox text="Password must have 8 or more characters" isShown={formError['PASSWORD_LENGTH']} />
            </Pane>

            <Pane
                marginTop={16}
                marginBottom={16}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width={280}
            >
                <Heading>Referrer</Heading>
                <Text size="small" marginBottom={16}>
                    Here because somebody referred you? Make sure you add their username here before registering
                </Text>
                <Heading size={100} marginBottom={4}>
                    REFERER USERNAME (Optional)
                </Heading>
                <TextInput
                    placeholder="janeappleseed"
                    value={uplineUsername}
                    onChange={(e) => setUplineUsername(e.target.value)}
                />
                <ErrorBox text="This username does not exist" isShown={formError['UPLINE_NOT_FOUND']} />
            </Pane>
            <Pane padding={0} marginBottom={16} width={280}>
                <Pane display="flex" flexDirection="row" gap={8} alignItems="center" justifyContent="flex-start">
                    <Checkbox checked={isTNCChecked} onChange={(e) => setIsTNCChecked(e.target.checked)} margin={0} />
                    <Text>
                        I Agree to the{' '}
                        <Link onClick={() => window.open('/privacy', '_blank', 'rel=noopener noreferrer')}>
                            Privacy Policy
                        </Link>
                    </Text>
                </Pane>
                <ErrorBox text="Please check this box to continue" isShown={formError['TNC_REQUIRED']} />
            </Pane>

            <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width={280}>
                <Link size="small" cursor="pointer" textDecoration="underline" onClick={handleToLogin}>
                    Login instead?
                </Link>
                <Button appearance="primary" onClick={handleSubmit} iconBefore={LogInIcon} isLoading={formLoading}>
                    Signup
                </Button>
            </Pane>
        </Pane>
    );
};

export default Signup;
