import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';

const endpoints = {
    ME: () => `/user/me`,
    LOGIN: () => `/user/login`,
    SIGNUP: () => `/user/signup`,
    TRANSACTIONS: () => `/transaction?include_downlines=true`,
    SALES_STATS: () => `/transaction/stats`,
    COMPANIES: () => `/company`,
    PRODUCTS_BY_COMPANY: (companyId) => `/product/company/${companyId}`,
    PRODUCT_BY_ID: (id) => `/product/${id}`,
    RESET_REQUEST: () => `/user/request-reset`,
    RESET: () => `/user/reset`,
    CHECK_ID: (id) => `/user/check/${id}`,
    CHECK_SESSION: (id) => `/user/session/check/${id}`
};

const authHeader = (token) => ({
    headers: { authorization: `Bearer ${token}` }
});

const instance = axios.create({
    baseURL: BASE_URL,
    headers: {}
});

const isResponseBad = (code) => code < 200 && code >= 300;

export const apiSignup = async (payload) => {
    const response = await instance.post(endpoints.SIGNUP(), payload);

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiLogin = async (payload) => {
    const response = await instance.post(endpoints.LOGIN(), payload);

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiMe = async (token) => {
    const response = await instance.get(endpoints.ME(), authHeader(token));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiGetSalesStats = async (token) => {
    const response = await instance.get(endpoints.SALES_STATS(), authHeader(token));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiGetTransactions = async (token) => {
    const response = await instance.get(endpoints.TRANSACTIONS(), authHeader(token));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiGetCompanies = async () => {
    const response = await instance.get(endpoints.COMPANIES());

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiGetProducts = async (companyId) => {
    const response = await instance.get(endpoints.PRODUCTS_BY_COMPANY(companyId));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const getProductDetails = async (token, id) => {
    const response = await instance.get(endpoints.PRODUCT_BY_ID(id), authHeader(token));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }

    return response.data;
};

export const apiRequestReset = async (email) => {
    const response = await instance.post(endpoints.RESET_REQUEST(), { email });

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }
};

export const apiReset = async (payload) => {
    const response = await instance.post(endpoints.RESET(), payload);

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }
};

export const apiCheckId = async (id) => {
    const response = await instance.get(endpoints.CHECK_ID(id));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }
};

export const apiCheckSession = async (session) => {
    const response = await instance.get(endpoints.CHECK_SESSION(session));

    if (isResponseBad(response.status)) {
        throw new Error(response.error);
    }
};
