import { Heading, Pane } from 'evergreen-ui';
import { COLORS } from '../components/atoms/colors';
import BgImg from '../assets/bg-home.png';
import Button from '../components/atoms/Button';
import { useEffect, useState } from 'react';
import { apiGetCompanies } from '../helpers/axios';
import Spacer from '../components/atoms/Spacer';
import Accordion from '../components/atoms/Accordion';
import HeaderV2 from '../components/HeaderV2';
import WhatsappIcon from '../assets/whatsapp-logo.png';
import { useNavigate } from 'react-router-dom';

const HomeV2 = () => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const nav = useNavigate();

    const fetchData = async () => {
        try {
            const res = await apiGetCompanies(null);
            setCompanies(res);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };
    const handleJoinNow = () => {
        nav('/signup');
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Pane>
            <HeaderV2 nav={nav} />
            <Pane position="fixed" bottom={16} right={16} display="flex" zIndex={10}>
                <img
                    src={WhatsappIcon}
                    width="60px"
                    onClick={() => window.open('https://wa.me/628111727995', '_blank')}
                    alt="whatsapp"
                />
            </Pane>
            <Pane
                backgroundImage={`url(${BgImg})`}
                backgroundSize="cover"
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={32}
            >
                <Heading color={COLORS.WHITE} fontSize="xx-large" textAlign="center" lineHeight={1} fontWeight={900}>
                    DAPATKAN CUAN TAMBAHAN SAMBIL REBAHAN DI VINT AGENCY
                </Heading>
                <Spacer size={8} />
                <Button text="JOIN NOW!" onClick={handleJoinNow} />
            </Pane>
            <Pane
                backgroundColor={COLORS.BLACK}
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                padding={32}
                paddingY={128}
            >
                <Heading color={COLORS.WHITE} fontWeight={900} fontSize="x-large">
                    COMPANY PARTNERS
                </Heading>
                <Pane display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" justifyContent="center">
                    {companies
                        .filter((item) => item.image_bw_url)
                        .map((item) => (
                            <img src={item.image_bw_url} width="100px" />
                        ))}
                </Pane>
                <Spacer size={16} />
                <Button text="More Products" appearance="secondary" onClick={() => nav('/home-catalog')} />
            </Pane>

            <Pane
                backgroundColor={COLORS.BLACK}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                padding={32}
                paddingY={128}
                width="100%"
            >
                <Heading color={COLORS.WHITE} fontWeight={900} fontSize="x-large">
                    COBA TANYA DISINI
                </Heading>
                <Pane width="100%">
                    <Accordion
                        title="Apa itu VinT Agency?"
                        innerText="VinT Agency bergerak di dunia sales dan affiliate Platform yang memberikan semua orang kesempatan untuk menjadi Agent sebagai Authorized Seller/Reseller, cocok untuk kalian yang ingin mencari usaha ataupun penghasilan tambahan. Partner Brand dalam bidang penjualan/pemasaran, bekerjasama dengan kalian pemilik usaha baik itu berupa produk/jasa/franchise"
                    />
                    <Accordion
                        title="Cara mendaftar sebagai member VinT Agency?"
                        innerText="Kalian cukup mengunjungi website resmi dari VinT Agency atau melalui teman kalian yang sudah terdaftar aktif di dalam Agency serta melakukan pembayaran lisensi 1x berlaku untuk selamanya"
                    />
                    <Accordion
                        title="Benefit join Vint Agency?"
                        innerText="Hak lisensi untuk menjadi Authorized Seller/Reseller dengan semua partner dari Agency (mendapatkan harga special, baik untuk melakukan bisnis ataupun penggunaan pribadi), Community untuk Networking dan membentuk peluang baru, Informasi benefit-benefit dan beberapa informasi ordal di beberapa sector."
                    />
                </Pane>
                <Spacer size={8} />
                <Button appearance="secondary" text="More FAQs" onClick={() => nav('/faq')} />
            </Pane>
        </Pane>
    );
};

export default HomeV2;
