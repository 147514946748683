import {
    BoxIcon,
    Card,
    Heading,
    HomeIcon,
    IconButton,
    LogOutIcon,
    Menu,
    MenuIcon,
    Pane,
    Popover,
    Text,
    TimelineLineChartIcon
} from 'evergreen-ui';
import { useUserContext } from '../contexts/UserContext';
import logo from '../assets/va-logo.png';
import { useNavigate } from 'react-router-dom';

const TopHeader = () => {
    const { currentUser, logout } = useUserContext();
    const nav = useNavigate();

    const handleNavigate = (to) => {
        nav(to);
    };
    return (
        <Card
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding={16}
            marginTop={16}
            borderRadius={4}
            width="100%"
            elevation={3}
        >
            <Pane display="flex" flexDirection="row" alignItems="center">
                <img
                    src={logo} // Replace this with the actual path to your PNG logo file
                    alt="Logo"
                    style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '16px'
                    }}
                />
                <Pane>
                    <Heading>{currentUser.name}</Heading>
                    <Text>{currentUser.username}</Text>
                </Pane>
            </Pane>

            <Popover
                position={'bottom-right'}
                content={
                    <Menu>
                        <Menu.Group>
                            <Menu.Item icon={HomeIcon} onClick={() => handleNavigate('/profile')}>
                                Home
                            </Menu.Item>
                            <Menu.Item icon={TimelineLineChartIcon} onClick={() => handleNavigate('/sales')}>
                                Sales
                            </Menu.Item>
                            <Menu.Item icon={BoxIcon} onClick={() => handleNavigate('/catalog')}>
                                Catalog
                            </Menu.Item>
                        </Menu.Group>
                        <Menu.Divider />
                        <Menu.Group>
                            <Menu.Item icon={LogOutIcon} intent="danger" onClick={logout}>
                                Log Out
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                }
            >
                <IconButton icon={MenuIcon} />
            </Popover>
        </Card>
    );
};

export default TopHeader;
