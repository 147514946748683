import { ArrowLeftIcon, Card, Heading, Icon, IconButton, Pane, Text } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { apiGetCompanies, apiGetProducts } from '../helpers/axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COLORS } from '../components/atoms/colors';
import Spacer from '../components/atoms/Spacer';
import HeaderV2 from '../components/HeaderV2';

const HomeCatalogV2 = () => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const [search, setSearch] = useSearchParams();
    const nav = useNavigate();

    const handleNavigate = (id) => {
        setSearch({ company: id });
        fetchProducts(id);
    };

    const fetchData = async () => {
        try {
            const res = await apiGetCompanies(null);
            setCompanies(res);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const fetchProducts = async (id) => {
        try {
            const res = await apiGetProducts(id);
            setCompany(res);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const handleBack = () => {
        setSearch({});
        setCompany(null);
    };

    useEffect(() => {
        const id = search.get('company');
        fetchData();
        if (id) {
            fetchProducts(id);
        } else {
            setCompany(null);
        }
    }, []);

    useEffect(() => {
        const id = search.get('company');
        fetchData();
        if (id) {
            fetchProducts(id);
        } else {
            setCompany(null);
        }
    }, [search]);

    return (
        <Pane
            background="linear-gradient(to bottom, black, #3B090D)"
            display="flex"
            alignItems="center"
            flexDirection="column"
            paddingTop={120}
        >
            <HeaderV2 nav={nav} />
            {company === null && (
                <Heading size={700} fontWeight={700} color={COLORS.WHITE}>
                    ALL PRODUCTS
                </Heading>
            )}

            <Pane display="flex" flexDirection="row" alignItems="center" marginTop={16} width="100%" paddingX={24}>
                {company != null && (
                    <Pane
                        appearance="primary"
                        onClick={handleBack}
                        marginRight={8}
                        backgroundColor={COLORS.RED}
                        padding={8}
                        borderRadius={100}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Icon icon={ArrowLeftIcon} margin={0} />
                    </Pane>
                )}
                <Heading size={700} color={COLORS.WHITE} marginTop={-3}>
                    {company?.name ? company.name : ''}
                </Heading>
            </Pane>
            <Spacer size={16} />
            <Pane display="flex" flexWrap="wrap" width="100%" justifyContent="space-between" paddingX={8}>
                {company === null &&
                    companies.map((item) => (
                        <Pane
                            margin={8}
                            padding={8}
                            onClick={() => handleNavigate(item.id)}
                            flexGrow={1}
                            flexBasis={150}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            backgroundColor="white"
                            borderRadius="16px"
                        >
                            <img src={item.image_url} width={120} height={120}></img>
                        </Pane>
                    ))}

                {company != null &&
                    company.products.map((item) => (
                        <Pane
                            marginBottom={8}
                            paddingRight={16}
                            paddingLeft={16}
                            paddingTop={24}
                            paddingBottom={24}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Pane
                                width="100%"
                                style={{ aspectRatio: '3 / 2' }}
                                backgroundColor="#edeff5"
                                backgroundImage={`url(${item.image_url})`}
                                backgroundSize="cover"
                                backgroundPosition="center"
                                borderRadius="8px"
                            />
                            <Pane marginTop={16}>
                                <Heading size={600} marginTop={8} color={COLORS.WHITE}>
                                    {item.name}
                                </Heading>
                                <Text marginTop={16} color={COLORS.WHITE}>
                                    {item.description}
                                </Text>
                            </Pane>
                        </Pane>
                    ))}
            </Pane>
            <Spacer size={64} />
        </Pane>
    );
};

export default HomeCatalogV2;
