import { Heading, Pane, Spinner, toaster } from 'evergreen-ui';
import { formatCurrency } from '../helpers/formatter';
import { useEffect, useState } from 'react';
import { apiGetSalesStats } from '../helpers/axios';
import { useUserContext } from '../contexts/UserContext';

const SalesStats = () => {
    const [salesData, setSalesData] = useState(null);
    const { token } = useUserContext();

    const fetchData = async () => {
        try {
            const res = await apiGetSalesStats(token);
            setSalesData(res);
        } catch (e) {
            console.error(e);
            toaster.danger("Something went wrong, we're investigating", { id: 'ERROR_500' });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!salesData) return <Spinner />;

    return (
        <Pane display="flex" flexDirection="column">
            <Heading size={100} marginTop={12}>
                PENDING COMMISSION
            </Heading>
            <Heading size={700} fontWeight={400}>
                {formatCurrency(salesData.totalPending)}
            </Heading>
            <Heading size={100} marginTop={12}>
                TOTAL ALLTIME REVENUE
            </Heading>
            <Heading size={700} fontWeight={400}>
                {formatCurrency(salesData.totalRevenue)}
            </Heading>
            <Heading size={100} marginTop={12}>
                TOTAL ALLTIME COMMISSION
            </Heading>
            <Heading size={700} fontWeight={400}>
                {formatCurrency(salesData.totalCommission)}
            </Heading>
            <Heading size={100} marginTop={12}>
                TOTAL COMMISSION FROM SALES
            </Heading>
            <Heading size={700} fontWeight={400}>
                {formatCurrency(salesData.commissionFromSales)}
            </Heading>
            <Heading size={100} marginTop={12}>
                TOTAL COMISSION FROM DOWNLINES
            </Heading>
            <Heading size={700} fontWeight={400}>
                {formatCurrency(salesData.commissionFromDownlines)}
            </Heading>
        </Pane>
    );
};

export default SalesStats;
