import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const PrivateRoute = ({ children }) => {
    const { currentUser } = useUserContext();
    const location = useLocation();

    if (currentUser && ['/signup', '/login'].includes(location.pathname)) {
        return <Navigate to={'/profile'} replace />;
    }

    if (!currentUser) {
        return <Navigate to={'/login'} replace />;
    }

    if (currentUser.status === 'PENDING' && !['/paymentrequired'].includes(location.pathname)) {
        return <Navigate to={'/paymentrequired'} replace />;
    }

    if (currentUser.status === 'SUSPENDED' && !['/suspended'].includes(location.pathname)) {
        return <Navigate to={'/suspended'} replace />;
    }

    return children ? children : <Outlet />;
};

export default PrivateRoute;
