import {
    ArrowLeftIcon,
    Button,
    DownloadIcon,
    Heading,
    IconButton,
    Pane,
    Pre,
    Spinner,
    Text,
    toaster
} from 'evergreen-ui';
import TopHeader from '../components/TopHeader';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from '../helpers/formatter';
import { getProductDetails } from '../helpers/axios';
import { useUserContext } from '../contexts/UserContext';

const CatalogProduct = () => {
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const nav = useNavigate();
    const params = useParams();
    const companyId = params.company_id;
    const productId = params.product_id;
    const { token } = useUserContext();

    const fetchData = async () => {
        try {
            const res = await getProductDetails(token, productId);
            setProduct(res);
        } catch (e) {
            console.error(e);
            toaster.danger("Something went wrong, we're investigating right now", { id: 'ERROR_500' });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) return <Spinner />;

    return (
        <Pane padding={16}>
            <TopHeader />
            <Pane padding={8} marginTop={16}>
                <Pane display="flex" flexDirection="row" alignItems="center">
                    <IconButton icon={ArrowLeftIcon} marginRight={8} onClick={() => nav(`/catalog/${companyId}`)} />
                    <Heading size={700}>{product.name}</Heading>
                </Pane>
            </Pane>
            <Pane marginBottom={128} padding={16}>
                <Pane
                    width="100%"
                    style={{ aspectRatio: '3 / 2' }}
                    backgroundColor="#edeff5"
                    backgroundImage={`url(${product.details_image_url || product.image_url})`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                />
                <Pane marginTop={16} marginBottom={16}>
                    <Pre whiteSpace="pre-wrap" wordBreak="break-word">
                        {product.description}
                    </Pre>
                    <Heading size={100} marginTop={16}>
                        RECOMMENDED SELL PRICE
                    </Heading>
                    <Text>{formatCurrency(product.recommended_sell_price)}</Text>
                    <Heading size={100} marginTop={16}>
                        YOUR COMMISSION
                    </Heading>
                    <Text>{formatCurrency(product.recommended_sell_price - product.agent_price)}</Text>
                    <Heading size={100} marginTop={16}>
                        UPLINE COMMISSION
                    </Heading>
                    <Text>{formatCurrency(product.agent_price - product.topline_agent_price)}</Text>
                </Pane>

                <Button
                    iconBefore={DownloadIcon}
                    onClick={() => window.open(product.promo_material_url, '_blank', 'noreferrer')}
                >
                    Download Promo Material
                </Button>
            </Pane>
        </Pane>
    );
};

export default CatalogProduct;
