import { Badge, CircleArrowDownIcon, DollarIcon, Heading, Pane, Spinner, Text } from 'evergreen-ui';
import { useUserContext } from '../contexts/UserContext';
import TopHeader from '../components/TopHeader';
import { useEffect, useState } from 'react';
import TEST_DATA from '../contexts/TEST_USER.json';
import { formatCurrency, formatDate } from '../helpers/formatter';
import SalesStats from './SalesStats';
import SalesTransactions from './SalesTransactions';

const Sales = () => {
    const { currentUser } = useUserContext();
    const [salesData, setSalesData] = useState(null);

    const fetchData = () => {
        setSalesData(TEST_DATA.sales);
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!salesData) return <Spinner />;

    return (
        <Pane padding={16}>
            <TopHeader />
            <Pane padding={8} marginTop={16}>
                <Heading size={700}>Sales Statistics</Heading>
                <SalesStats />
            </Pane>
            <Pane padding={8} marginTop={16}>
                <Heading size={700} marginBottom={16}>
                    Transactions
                </Heading>
                <SalesTransactions />
            </Pane>
        </Pane>
    );
};

export default Sales;
