import { InlineAlert } from 'evergreen-ui';

const ErrorBox = ({ text, isShown }) => {
    if (!isShown) return null;

    return (
        <InlineAlert marginTop={8} marginBottom={8} intent="danger" size={300} opacity={0.7}>
            {text}
        </InlineAlert>
    );
};

export default ErrorBox;
