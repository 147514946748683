import { COLORS } from './colors';
const Button = ({ text, onClick, appearance }) => {
    const secondaryStyle = {
        backgroundColor: COLORS.BLACK,
        color: COLORS.WHITE,
        fontWeight: 700,
        border: '2px solid white',
        borderRadius: '100px',
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 8,
        cursor: 'pointer'
    };

    const primaryStyle = {
        backgroundColor: COLORS.RED,
        color: COLORS.WHITE,
        fontWeight: 700,
        border: 'none',
        borderRadius: 12,
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 8,
        cursor: 'pointer'
    };

    const style = appearance === 'secondary' ? secondaryStyle : primaryStyle;
    return (
        <button onClick={onClick} style={style}>
            {text}
        </button>
    );
};

export default Button;
