import { Button, Heading, LogOutIcon, Pane, Paragraph } from 'evergreen-ui';
import { useUserContext } from '../contexts/UserContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Suspended = () => {
    const { currentUser, logout } = useUserContext();
    const nav = useNavigate();

    useEffect(() => {
        if (currentUser.status === 'PENDING') nav('/paymentrequired');
        if (currentUser.status === 'ACTIVE') nav('/profile');
    }, []);

    return (
        <Pane padding={16}>
            <Pane padding={8} marginTop={16} display="flex" alignItems="center" flexDirection="column">
                <Heading size={700} marginBottom={16}>
                    Your Account is Not Available for Use
                </Heading>
                <Paragraph>
                    Maaf Akun anda sedang kami suspend dikarenakan ada dugaan melakukan pelanggaran. untuk informasi
                    lebih lanjut dan tanggapan bisa menghubungi CS di WA +628111727995
                </Paragraph>

                <Button onClick={logout} iconBefore={LogOutIcon} intent="danger" marginTop={16} marginBottom={64}>
                    Log Out
                </Button>
            </Pane>
        </Pane>
    );
};

export default Suspended;
