import {
    Button,
    EyeOffIcon,
    EyeOnIcon,
    Heading,
    IconButton,
    Link,
    LogInIcon,
    Pane,
    TextInput,
    toaster
} from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isHidden, setIsHidden] = useState(true);
    const { login, currentUser } = useUserContext();
    const nav = useNavigate();

    const handleSubmit = async () => {
        try {
            await login(username, password);
            nav('/profile');
        } catch (err) {
            console.error(err);
            toaster.danger('Invalid Login Credentials. Please try again.');
        }
    };

    const handleToSignup = () => {
        nav('/signup');
    };

    const handleToForgot = () => {
        nav('/forgot');
    };

    const handleHidden = () => {
        setIsHidden(!isHidden);
    };

    useEffect(() => {
        if (currentUser) nav('/profile');
    }, []);

    return (
        <Pane display="flex" flexDirection="column" alignItems="center" gap={8} paddingTop={32} height="80vh">
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Heading size={900}>Log In</Heading>
            <Pane>
                <Pane display="flex" flexDirection="column" alignItems="flex-start" marginBottom={8}>
                    <Heading size={100} marginBottom={4}>
                        Username
                    </Heading>
                    <TextInput placeholder="johndoe" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Pane>

                <Pane display="flex" flexDirection="column" alignItems="flex-start">
                    <Heading size={100} marginBottom={4}>
                        Password
                    </Heading>
                    <Pane display="flex" flexDirection="row">
                        <TextInput
                            placeholder="******"
                            type={isHidden ? 'password' : 'text'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <IconButton marginLeft={8} icon={isHidden ? EyeOnIcon : EyeOffIcon} onClick={handleHidden} />
                    </Pane>
                </Pane>
            </Pane>

            <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width={280}
                marginTop={16}
            >
                <Pane display="flex" flexDirection="column">
                    <Link
                        size="small"
                        cursor="pointer"
                        textDecoration="underline"
                        onClick={handleToSignup}
                        marginBottom={4}
                    >
                        Register instead?
                    </Link>
                    <Link size="small" cursor="pointer" textDecoration="underline" onClick={handleToForgot}>
                        Forgot Password?
                    </Link>
                </Pane>
                <Button appearance="primary" onClick={handleSubmit} iconBefore={LogInIcon}>
                    Login
                </Button>
            </Pane>
        </Pane>
    );
};

export default Login;
