import { Card, Heading, Pane, Spinner, Text, toaster } from 'evergreen-ui';
import TopHeader from '../components/TopHeader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetCompanies } from '../helpers/axios';
import { useUserContext } from '../contexts/UserContext';
const Catalog = () => {
    const [companies, setCompanies] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const nav = useNavigate();
    const { token } = useUserContext();

    const fetchData = async () => {
        try {
            const res = await apiGetCompanies(token);
            setCompanies(res);
        } catch (e) {
            console.error(e);
            toaster.danger("Something went wrong, we're investigating right now", { id: 'ERROR_500' });
        }
        setIsLoading(false);
    };

    const handleNavigate = (id) => {
        nav('/catalog/' + id, {});
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) return <Spinner />;

    return (
        <Pane padding={16}>
            <TopHeader />
            <Pane padding={8} marginTop={16}>
                <Heading size={700}>Catalog</Heading>
                <Text>Browse companies and products you'd love to sell</Text>
            </Pane>
            <Pane display="flex" flexWrap="wrap" width="100%" justifyContent="space-between">
                {companies.map((item) => (
                    <Card
                        elevation={2}
                        margin={8}
                        paddingRight={16}
                        paddingLeft={16}
                        paddingTop={24}
                        paddingBottom={24}
                        onClick={() => handleNavigate(item.id)}
                        flexGrow={1}
                        flexBasis={150}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <img src={item.image_url} width={120} height={120}></img>
                        <Pane width={120}>
                            <Heading marginTop={8} wordWrap="break-word">
                                {item.name}
                            </Heading>
                        </Pane>
                    </Card>
                ))}
            </Pane>
        </Pane>
    );
};

export default Catalog;
