import { Heading, Link, Pane, Text } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { COLORS } from './atoms/colors';

const Footer = () => {
    const nav = useNavigate();
    return (
        <Pane
            width="100%"
            backgroundColor={COLORS.GREY1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={4}
            paddingTop={64}
            paddingBottom={128}
            paddingLeft={48}
            paddingRight={48}
        >
            <Pane>
                <Heading onClick={() => nav('/')} cursor="pointer" color="white" size={600}>
                    VinTAgency
                </Heading>
            </Pane>
            <Pane display="flex" flexDirection="column" alignItems="flex-end" gap={4}>
                <Text
                    onClick={() => window.open('/tnc', '_blank', 'rel=noopener noreferrer')}
                    cursor="pointer"
                    color="white"
                >
                    Terms and Conditions
                </Text>
                <Text
                    onClick={() => window.open('/faq', '_blank', 'rel=noopener noreferrer')}
                    cursor="pointer"
                    color="white"
                >
                    FAQ
                </Text>
                <Text
                    onClick={() => window.open('/privacy', '_blank', 'rel=noopener noreferrer')}
                    cursor="pointer"
                    color="white"
                >
                    Privacy Policy
                </Text>
                <Text
                    onClick={() => window.open('/contact-us', '_blank', 'rel=noopener noreferrer')}
                    cursor="pointer"
                    color="white"
                >
                    Contact Us
                </Text>
            </Pane>
        </Pane>
    );
};

export default Footer;
