import React, { useState, useContext, useEffect } from 'react';
import { apiLogin, apiMe, apiSignup } from '../helpers/axios';
import { useNavigate } from 'react-router-dom';
import { Spinner, toaster } from 'evergreen-ui';
import { reactLocalStorage } from 'reactjs-localstorage';

const UserContext = React.createContext();
const useUserContext = () => useContext(UserContext);

const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [token, setToken] = useState('');
    const [pending, setPending] = useState(true);
    const nav = useNavigate();

    const getUserDetails = async (newToken) => {
        setPending(true);
        try {
            const res = await apiMe(newToken);
            setCurrentUser(res);
        } catch (e) {
            console.error(e);
        }
        setPending(false);
    };

    const signup = async (payload) => {
        const data = await apiSignup(payload);
        setToken(data.token);
        reactLocalStorage.set('tokenvintagency', data.token);
        await getUserDetails(data.token);
    };

    const login = async (username, password) => {
        const payload = { username, password };
        const data = await apiLogin(payload);
        setToken(data.token);
        reactLocalStorage.set('tokenvintagency', data.token);
        await getUserDetails(data.token);
    };

    const logout = () => {
        setCurrentUser(null);
        setToken(null);
        reactLocalStorage.clear('tokenvintagency');
        nav('/login');
    };

    const refetchUser = () => {
        getUserDetails(token);
    };

    useEffect(() => {
        const localToken = reactLocalStorage.get('tokenvintagency', null, true);
        if (localToken) setToken(localToken);
        getUserDetails(localToken);
    }, []);

    if (pending) return <Spinner />;

    return (
        <UserContext.Provider
            value={{
                currentUser,
                token,
                refetch: refetchUser,
                pending,
                login,
                logout,
                signup
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, useUserContext };
