import { Route, Routes } from 'react-router-dom';
import Profile from './views/Profile';
import Sales from './views/Sales';
import Catalog from './views/Catalog';
import Login from './views/Login';
import { UserProvider } from './contexts/UserContext';
import CatalogCompany from './views/CatalogCompany';
import CatalogProduct from './views/CatalogProduct';
import PrivateRoute from './components/PrivateRoute';
import Signup from './views/Signup';
import Home from './views/Home';
import Paywall from './views/Paywall';
import Suspended from './views/Suspended';
import RequestReset from './views/RequestReset';
import Reset from './views/Reset';
import PrivacyPolicy from './views/PrivacyPolicy';
import Tnc from './views/Tnc';
import Faq from './views/Faq';
import Contact from './views/Contact';
import HomeV2 from './views/HomeV2';
import HomeCatalogV2 from './views/HomeCatalogV2';

const MainRoutes = () => {
    return (
        <UserProvider>
            <Routes>
                <Route path="/" element={<HomeV2 />} />
                <Route path="/v1" element={<Home />} />
                <Route path="/home-catalog" element={<HomeCatalogV2 />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot" element={<RequestReset />} />
                <Route path="/reset-pass" element={<Reset />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/tnc" element={<Tnc />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/paymentrequired" element={<Paywall />} />
                    <Route path="/suspended" element={<Suspended />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/sales" element={<Sales />} />
                    <Route path="/catalog" element={<Catalog />} />
                    <Route path="/catalog/:company_id" element={<CatalogCompany />} />
                    <Route path="/catalog/:company_id/:product_id" element={<CatalogProduct />} />
                </Route>
            </Routes>
        </UserProvider>
    );
};

export default MainRoutes;
