import { Badge, CircleArrowDownIcon, DollarIcon, Heading, Pane, Spinner, Text, toaster } from 'evergreen-ui';
import { formatCurrency, formatDate } from '../helpers/formatter';
import { useEffect, useState } from 'react';
import { apiGetTransactions } from '../helpers/axios';
import { useUserContext } from '../contexts/UserContext';
import { calculateCommission } from '../helpers/commission';

const SalesTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [pending, setPending] = useState(true);
    const { token, currentUser } = useUserContext();

    const fetchData = async () => {
        try {
            const res = await apiGetTransactions(token);
            setTransactions(res);
            setPending(false);
        } catch (e) {
            console.error(e);
            toaster.danger("Something went wrong, we're investigating", { id: 'ERROR_500' });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (pending) return <Spinner />;

    return (
        <Pane display="flex" flexDirection="column">
            {transactions.map((item) => {
                let type = 'SELF_NOUPLINE';
                if (item.user.username !== currentUser.username) type = 'DOWNLINE';
                else if (item.upline) type = 'SELF_WITHUPLINE';

                const commission = calculateCommission(item, type);

                return (
                    <Pane
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={16}
                        width="100%"
                        paddingBottom={8}
                        borderBottom="solid 1px #E6E8F0"
                    >
                        <Pane display="flex" alignItems="center">
                            {type === 'DOWNLINE' ? <CircleArrowDownIcon /> : <DollarIcon />}
                            <Pane display="flex" flexDirection="column" marginLeft={8}>
                                <Heading size={500}>{item?.product?.name}</Heading>
                                <Pane display="flex" alignItems="center">
                                    <Text>{formatCurrency(commission)}</Text>
                                    {item.status === 'PENDING' ||
                                        (item.status === 'PAYMENT_RECEIVED' && <Badge marginLeft={8}>PENDING</Badge>)}
                                </Pane>
                            </Pane>
                        </Pane>
                        <Pane display="flex" alignItems="flex-end" flexDirection="column">
                            <Heading size={400}>{formatDate(item.created)}</Heading>
                            <Text>{item.user.username}</Text>
                        </Pane>
                    </Pane>
                );
            })}
        </Pane>
    );
};

export default SalesTransactions;
