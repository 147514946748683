import {
    Button,
    Heading,
    ListItem,
    LogInIcon,
    LogOutIcon,
    NewPersonIcon,
    Pane,
    Paragraph,
    Text,
    UnorderedList
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const FAQS = [
    {
        q: 'Apa itu VinT Agency?',
        a: 'VinT Agency bergerak di dunia sales dan affiliate Platform yang memberikan semua orang kesempatan untuk menjadi Agent sebagai Authorized Seller/Reseller, cocok untuk kalian yang ingin mencari usaha ataupun penghasilan tambahan. Partner Brand dalam bidang penjualan/pemasaran, bekerjasama dengan kalian pemilik usaha baik itu berupa produk/jasa/franchise'
    },
    {
        q: 'Bagaimana cara mendaftar sebagai member VinT Agency?',
        a: 'Kalian cukup mengunjungi website resmi dari VinT Agency atau melalui teman kalian yang sudah terdaftar aktif di dalam Agency serta melakukan pembayaran lisensi 1x berlaku untuk selamanya'
    },
    {
        q: 'Perusahaan apa saja yang bisa bekerjasama?',
        a: 'VinT Agency menerima kerjasama dengan Perusahaan apapun, asalkan sudah establish , memiliki track record baik, sudah memiliki sistem yang baik, memberikan komisi yang di sepakati, produk sudah memiliki BPOM atau hal yang menjadi standar pemerintah(jika berupa produk).'
    },
    {
        q: 'Cara mengajukan kerjasama dengan Vint Agency',
        a: 'Untuk Kalian yang memiliki produk / jasa ataupun yang ingin mengajukan produk / jasa dan ingin bekerjasama dengan Vint Agency silahkan hubungi admin untuk pengajuan dan informasi lebih lanjut mengenai persyaratan pengajuan kerjasama.'
    },
    {
        q: 'Benefit joint Vint Agency (Berlaku Seumur Hidup)',
        a: 'Hak lisensi untuk menjadi Authorized Seller/Reseller dengan semua partner dari Agency (mendapatkan harga special, baik untuk melakukan bisnis ataupun penggunaan pribadi), Community untuk Networking dan membentuk peluang baru, Informasi benefit-benefit dan beberapa informasi ordal di beberapa sector.'
    },
    { q: 'Apa saja yang dilakukan di Vint Agency', a: 'Penjualan/Pemasaran, Affiliasi, Rekrut, Belanja.' },
    {
        q: 'Siapa saja yang bisa bergabung dengan Vint Agency?',
        a: 'Siapapun dapat untuk bergabung denga Vint Agency, dengan mengikuti semua prosedur dan TNC yang di miliki oleh Agency, Untuk kalian baik pelajar pegawai pengusaha ibu rumah tangga maupun pengangguran tapi ‘MAU’ mendapatkan penghasilan atau mendapatkan Privilage beserta diskon untuk berbagai hal bisa mengikuti Vint Agency.'
    },
    {
        q: 'Tujuan dibentuknya Community Vint Agency',
        a: 'VinT Agency diharapkan menjadi tempat untuk setiap orang dapat bertemu dengan orang baru, teman, kolega, ataupun rekan bisnis nantinya, Community di harapkan bisa saling support dan bertukar informasi-informasi tertentu, Community akan menjadi support sistem untuk bertukar pikiran maupun menjadi tempat untuk saling membantu dalam berbagai hal, diharapkan banyak hal dapat saling terhubung di dalam komunitas yang kita jalani.'
    },
    {
        q: 'Berapa Biaya yang perlu di keluarkan?',
        a: 'Cukup dengan 100 Ribuan saja kamu bisa mendapatkan banyak benefit dan maanfaat menjadi member Vint Agency.'
    },
    { q: 'Kapan VinT Agnecy didirikan?', a: 'Pada Tanggal 20 Oktober 2023' },
    {
        q: 'Apa perbedaan VinT Agency dengan Agency lainnya?',
        a: 'Hanya di VinT Agency kamu bisa kerja dimana saja dan kapan saja bahkan bisa mendapatkan penghasilan tanpa terlihat bekerja'
    },
    {
        q: 'Kenapa harus memilih VinT Agency?',
        a: 'Hanya di VinT Agency kamu akan mendapatkan benefit tanpa menjadi tekanan untuk menjalankan! Dengan Rp 100rb kamu sudah mendapatkan membership yang berlaku seumur hidup dengan semua benefit yang ada. Tidak ada tekanan yang di berikan, kecuali kalian membutuhkan dorongan. Yang paling penting tidak ada kewajiban untuk membeli ini itu di dalamnya! Segala hal yang di infokan adalah informasi saja tanpa adanya kewajiban untuk di lakukan. Banyak support system yang disediakan untuk membantu kamu dalam segala proses untuk closing/rekrut'
    },
    {
        q: 'Apakah ada kelas bimbingan?',
        a: 'VinT Agency menyediakan kelas bimbingan untuk para member setiap minggunya, dan member bisa berdiskusi secara langsung di group informatif apabila ada pertanyaan-pertanyaan yang ingin ditanyakan.'
    },
    {
        q: 'Apa syarat menjadi member VinT Agnecy?',
        a: 'Tidak ada syarat khusus menjadi member VinT Agency karena VinT Agency berpeluang bagi siapa pun dimana pun yang ingin bergabung dengan VinT Agency.'
    },
    {
        q: 'Siapa saja kolega VinT Agency?',
        a: 'VinT Agency bekerjasama dengan 12 perusahaan diantaranya : Vibe, Sideroom, MSIG life, Dear X, Madam Jade, Miss Sky, Aerospace, Min soo, Cinepolis, Evena, Bali Babe, Miss Face'
    }
];

const Faq = () => {
    const nav = useNavigate();

    return (
        <Pane paddingTop={32} padding={32}>
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Pane width="100%" marginTop={16} display="flex" flexDirection="column" marginBottom={128}>
                <Heading size={700} marginBottom={16}>
                    Frequently Asked Questions
                </Heading>
                {FAQS.map((item) => (
                    <Pane marginBottom={16}>
                        <Heading size={400} fontWeight={700} marginBottom={8}>
                            {item.q}
                        </Heading>
                        <Text>{item.a}</Text>
                    </Pane>
                ))}
            </Pane>
        </Pane>
    );
};

export default Faq;
