import { CircleArrowUpIcon, Heading, Label, ListItem, OrderedList, Pane, Text, UserIcon } from 'evergreen-ui';
import { useUserContext } from '../contexts/UserContext';
import TopHeader from '../components/TopHeader';
import UserCard from '../components/UserCard';

const Profile = () => {
    const { currentUser } = useUserContext();
    return (
        <Pane padding={16}>
            <TopHeader />
            <Pane padding={8} marginTop={16}>
                <Heading size={700}>Langkah-langkah untuk menjalankan VinT Agency</Heading>
                <OrderedList>
                    <ListItem>
                        Setelah melakukan Pendaftaran silahkan mulai dengan melihat produk dan jasa apa saja yang
                        bekerjasama dengan VinT Agency
                    </ListItem>
                    <ListItem>
                        Kemudian kalian bisa mulai memilih ingin fokus produk tertentu atau mau memilih semua produk
                        ataupun tidak memilih sama sekali, setelah menentukan kalian bisa menghubungi admin terkait join
                        grup khusus tiap Perusahaan dan grup komunitas Agency.
                    </ListItem>
                    <ListItem>
                        Untuk mengenai komisi yang mungkin terjadi atau harga modal kita dapat di cek langsung melalui
                        website di bagian catalog
                    </ListItem>
                    <ListItem>
                        Untuk Informasi atau penjelasan mengenai Agency akan di lakukan setiap senin jam 20.00 WIB, jika
                        kalian ingin mengajak teman kalian bisa mengajak mereka mengikuti meeting onlinenya
                    </ListItem>
                    <ListItem>
                        Kalian akan mendapatkan banyak informasi di dalam grup Community mengenai Benefit dan Informasi
                        Perusahaan yang akan bekerjasama
                    </ListItem>
                    <ListItem>
                        Silahkan mulai untuk berafiliasi dengan memberikan informasi kepada orang lain untuk melakukan
                        transaksi melalui admin khusus kalian dan jangan lupa untuk menyampaikan kalau kalian yang
                        merekomendasikan. Silahkan melakukan Penjualan sendiri jika ingin meningkatkan skill sales
                        kalian. Serta jangan lupa untuk melakukan rekrutmen agar kalian bisa mendapatkan bonus rekrut
                        dan juga passive income dari hasil yang di dapatkan orang yang kalian rekrut
                    </ListItem>
                </OrderedList>
            </Pane>
            <Pane padding={8} marginTop={16}>
                <Heading size={700}>Profile</Heading>
                <Pane display="flex" flexDirection="column">
                    <Heading size={100} marginTop={12}>
                        NAME
                    </Heading>
                    <Text>{currentUser.name}</Text>
                    <Heading size={100} marginTop={12}>
                        EMAIL
                    </Heading>
                    <Text>{currentUser.email}</Text>
                    <Heading size={100} marginTop={12}>
                        USERNAME
                    </Heading>
                    <Text>{currentUser.username}</Text>
                    <Heading size={100} marginTop={12}>
                        PHONE NUMBER
                    </Heading>
                    <Text>{currentUser.phone}</Text>
                    <Heading size={100} marginTop={12}>
                        DOMICILE
                    </Heading>
                    <Text>{currentUser.domicile}</Text>
                    <Heading size={100} marginTop={12}>
                        BANK ACCOUNT
                    </Heading>
                    <Text>{currentUser.bank_account}</Text>
                </Pane>
            </Pane>
            <Pane padding={8} marginTop={16}>
                <Heading size={700}>Upline</Heading>
                {currentUser.upline ? (
                    <UserCard
                        name={currentUser.upline.name}
                        username={currentUser.upline.username}
                        icon={CircleArrowUpIcon}
                    />
                ) : (
                    <Text size={300} opacity={0.4} marginTop={8}>
                        No Upline, You're on top!
                    </Text>
                )}
            </Pane>
            <Pane padding={8} marginTop={16}>
                <Heading size={700}>Downlines</Heading>
                <Text>{currentUser.downlines.length} members</Text>
                {currentUser.downlines.map((item) => (
                    <UserCard name={item.name} username={item.username} icon={UserIcon} />
                ))}
            </Pane>
        </Pane>
    );
};

export default Profile;
