import { Button, Heading, Link, LogInIcon, Pane, ResetIcon, Text, TextInput, toaster } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { apiRequestReset } from '../helpers/axios';
import HeaderV2 from '../components/HeaderV2';
import Spacer from '../components/atoms/Spacer';

const RequestReset = () => {
    const [email, setEmail] = useState('');
    const [isProcessCompleted, setIsProcessCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser } = useUserContext();
    const nav = useNavigate();

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await apiRequestReset(email);
            setIsProcessCompleted(true);
            setIsLoading(false);
        } catch (err) {
            if (err?.response?.data?.error_code === 'NOT_FOUND_ERROR') {
                toaster.danger('Invalid Email Address', { id: 'INVALID_EMAIL' });
            } else {
                toaster.danger('Something went wrong, we are investigating right now', { id: 'ERROR_500' });
            }

            setIsLoading(false);
        }
    };

    const handleToLogin = () => {
        nav('/login');
    };

    useEffect(() => {
        if (currentUser) nav('/profile');
    }, []);

    return (
        <Pane display="flex" flexDirection="column" alignItems="center" gap={8} paddingTop={32} height="80vh">
            <HeaderV2 nav={nav} />
            <Spacer size={64} />
            <Heading size={900}>Forgot your password?</Heading>
            <Link size="small" cursor="pointer" textDecoration="underline" onClick={handleToLogin}>
                Back to Login
            </Link>
            {isProcessCompleted ? (
                <Text>Reset link has been sent to your email!</Text>
            ) : (
                <Pane marginTop={16}>
                    <Pane display="flex" flexDirection="column" alignItems="flex-start" marginBottom={8}>
                        <Heading size={100} marginBottom={4}>
                            Email
                        </Heading>
                        <TextInput
                            placeholder="johndoe@email.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Pane>

                    <Pane
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={280}
                    >
                        <Button
                            appearance="primary"
                            onClick={handleSubmit}
                            iconBefore={ResetIcon}
                            isLoading={isLoading}
                        >
                            Reset Password
                        </Button>
                    </Pane>
                </Pane>
            )}
        </Pane>
    );
};

export default RequestReset;
