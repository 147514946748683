import { Heading, Pane, Text } from 'evergreen-ui';
import { COLORS } from './colors';
import { useState } from 'react';

const Accordion = ({ title, innerText }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Pane width="100%" marginTop={16}>
            <Pane
                backgroundColor={COLORS.GREY1}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                paddingX={16}
                paddingY={12}
                borderRadius="16px"
                zIndex={5}
                position="relative"
                onClick={() => setExpanded(!expanded)}
            >
                <Heading size={500} color={COLORS.WHITE}>
                    {title}
                </Heading>
                <Heading size={700} color={COLORS.WHITE}>
                    {expanded ? '-' : '+'}
                </Heading>
            </Pane>
            {expanded && (
                <Pane
                    backgroundColor={COLORS.GREY2}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    paddingTop={52}
                    paddingBottom={24}
                    paddingX={12}
                    borderRadius="16px"
                    marginTop={-32}
                    zIndex={2}
                    position="relative"
                >
                    <Text color={COLORS.WHITE}>{innerText}</Text>
                </Pane>
            )}
        </Pane>
    );
};

export default Accordion;
