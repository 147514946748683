import { Pane } from 'evergreen-ui';
import Logo from '../assets/logo.png';
import Button from './atoms/Button';

const HeaderV2 = ({ nav }) => {
    return (
        <Pane
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="fixed"
            top={0}
            left={0}
            zIndex={10}
        >
            <Pane
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding={16}
                width="100%"
                background="linear-gradient(to bottom, black, transparent)"
                maxWidth="600px"
            >
                <img src={Logo} width="70px" alt="logo" onClick={() => nav('/')} />
                <Pane display="flex" flexDirection="row" gap={8}>
                    <Button text="Daftar" onClick={() => nav('/signup')} />
                    <Button text="Masuk" onClick={() => nav('/login')} />
                </Pane>
            </Pane>
        </Pane>
    );
};

export default HeaderV2;
