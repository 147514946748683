import { Heading, Link, Pane, Paragraph, Text } from 'evergreen-ui';
import { useUserContext } from '../contexts/UserContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import qrImage from '../assets/qr.jpeg';
import Button from '../components/atoms/Button';
import Spacer from '../components/atoms/Spacer';

const Paywall = () => {
    const { currentUser, logout } = useUserContext();
    const nav = useNavigate();

    useEffect(() => {
        if (currentUser.status === 'SUSPENDED') nav('/suspended');
        if (currentUser.status === 'ACTIVE') nav('/profile');
    }, []);

    return (
        <Pane>
            <Pane paddingY={8} paddingX={32} marginTop={16} display="flex" alignItems="center" flexDirection="column">
                <Heading size={700} marginBottom={8}>
                    Payment Required
                </Heading>
                <Paragraph textAlign="center">
                    Pembayaran hanya Rp. 100.000 <Text textDecoration="line-through">(Rp. 500.000)</Text> untuk
                    mengaktivasi akun kirimkan konfirmasi melalui chat{' '}
                    <Link
                        textDecoration="underline"
                        cursor="pointer"
                        onClick={() => window.open('https://wa.me/628111727995', '_blank')}
                    >
                        WA +628111727995
                    </Link>
                </Paragraph>
                <img
                    src={qrImage}
                    alt="QR"
                    style={{
                        width: '90%',
                        aspectRatio: '1135 / 1600'
                    }}
                />
                <Spacer size={16} />
                <Button
                    text="SAYA SUDAH MELAKUKAN PEMBAYARAN"
                    onClick={() => window.open('https://wa.me/628111727995', '_blank')}
                />
                <Button onClick={logout} text="LOG OUT" appearance="secondary" />
                <Spacer size={32} />
            </Pane>
        </Pane>
    );
};

export default Paywall;
