export const CITIES = [
    'Banda Aceh',
    'Langsa',
    'Lhokseumawe',
    'Sabang',
    'Subulussalam',
    'Denpasar',
    'Pangkalpinang',
    'Cilegon',
    'Serang',
    'Tangerang Selatan',
    'Tangerang',
    'Bengkulu',
    'Yogyakarta',
    'Gorontalo',
    'Kota Administrasi Jakarta Barat',
    'Kota Administrasi Jakarta Pusat',
    'Kota Administrasi Jakarta Selatan',
    'Kota Administrasi Jakarta Timur',
    'Kota Administrasi Jakarta Utara',
    'Sungai Penuh',
    'Jambi',
    'Bandung',
    'Bekasi',
    'Bogor',
    'Cimahi',
    'Cirebon',
    'Depok',
    'Sukabumi',
    'Tasikmalaya',
    'Banjar',
    'Magelang',
    'Pekalongan',
    'Salatiga',
    'Semarang',
    'Surakarta',
    'Tegal',
    'Batu',
    'Blitar',
    'Kediri',
    'Madiun',
    'Malang',
    'Mojokerto',
    'Pasuruan',
    'Probolinggo',
    'Surabaya',
    'Pontianak',
    'Singkawang',
    'Banjarbaru',
    'Banjarmasin',
    'Palangka Raya',
    'Balikpapan',
    'Bontang',
    'Samarinda',
    'Nusantara',
    'Tarakan',
    'Batam',
    'Tanjungpinang',
    'Bandar Lampung',
    'Metro',
    'Ternate',
    'Tidore Kepulauan',
    'Ambon',
    'Tual',
    'Bima',
    'Mataram',
    'Kupang',
    'Sorong',
    'Jayapura',
    'Dumai',
    'Pekanbaru',
    'Makassar',
    'Palopo',
    'Parepare',
    'Palu',
    'Baubau',
    'Kendari',
    'Bitung',
    'Kotamobagu',
    'Manado',
    'Tomohon',
    'Bukittinggi',
    'Padang',
    'Padang Panjang',
    'Pariaman',
    'Payakumbuh',
    'Sawahlunto',
    'Solok',
    'Lubuklinggau',
    'Pagar Alam',
    'Palembang',
    'Prabumulih',
    'Binjai',
    'Gunungsitoli',
    'Medan',
    'Padangsidimpuan',
    'Pematangsiantar',
    'Sibolga',
    'Tanjungbalai',
    'Tebing Tinggi'
];
